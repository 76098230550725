import "styles/pages/page-pricing.scss";

import React, { useState } from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import Breadcrumps from "components/Breadcrumps";
import BookingModal from "components/BookingModal";

const PricingPage = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pagePricing;
  const [tab, setTab] = useState("services");
  const [bookingModal, setBookingModal] = useState(false);

  const pricingData =
    tab === "services" ? pageData?.pricing : pageData?.pricingTests;

  return (
    <Layout>
      <Breadcrumps data={pageContext.title} />
      <Seo title={seo.title} description={seo.metaDesc} />
      <SubpageHeader title={pageContext.data.title} />

      <section className="pricing-main">
        <div className="container">
          <div className="pricing-main__info">
            Cennik poradni psychologicznej w Warszawie i Wołominie
          </div>

          <div className="pricing-main__nav">
            <button
              onClick={() => setTab("services")}
              className={tab === "services" ? "current" : ""}
            >
              Usługi
            </button>
            <button
              onClick={() => setTab("tests")}
              className={tab === "tests" ? "current" : ""}
            >
              Testy psychologiczne
            </button>
          </div>

          {tab === "tests" && (
            <p className="pricing-main__desc">
              Przed każdym badaniem niezbędny jest wywiad diagnostyczny. Każde
              badanie może być poszerzone o dodatkowe konsultacje lub testy,
              jeśli diagnosta uzna to za konieczne.
            </p>
          )}

          {pricingData?.map((item, index) => (
            <div className="pricing-main__single-pricing" key={index}>
              <h2>{item.title}</h2>
              <Divider marginTop={15} marginBottom={20} />
              <div className="pricing-main__table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <td>Świadczenie</td>
                      <td>Stacjonarnie/Online</td>
                      <td>Czas</td>
                      <td>Cena</td>
                    </tr>
                  </thead>
                  <tbody>
                    {item.table?.map((row, index) => (
                      <tr key={index}>
                        <td>{row.table1}</td>
                        <td>{row.table2}</td>
                        <td>{row.table3}</td>
                        <td>{row.table4}</td>
                        <td>
                          <Link
                            to="/rezerwacja-wizyt/"
                            className="button button--small button--green"
                          >
                            {row.contactBtn ? "Skontaktuj się" : "Umów wiztę"}
                          </Link>
                          {row.internalUrl && (
                            <>
                              <br />
                              <Link
                                to={row.internalUrl}
                                className="button button--small button--red"
                              >
                                Sprawdź ofertę
                              </Link>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className="pricing-main__price-info">
            Podane ceny mają jedynie charakter informacyjny i nie stanowią
            oferty handlowej w rozumieniu Art. 66 par.1 Kodeksu Cywilnego.
          </div>
          <Divider marginTop={45} marginBottom={45} />
          <div className="pricing-main__methods">
            <h3>Metody płatności w naszej poradni</h3>
            <div className="pricing-main__logos">
              <img
                src={require("assets/images/visa.svg").default}
                alt=""
                className="img-fluid"
              />
              <img
                src={require("assets/images/mastercard.svg").default}
                alt=""
                className="img-fluid"
              />
              <img
                src={require("assets/images/blik.svg").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div>Akceptujemy również płatność gotówką na miejscu.</div>
          </div>
          <Divider marginTop={50} marginBottom={50} />
          <div className="pricing-main__buttons">
            <button
              onClick={() => setBookingModal(true)}
              className="button button--red"
            >
              Zadzwoń
            </button>
            <Link to="/kontakt/" className="button button--orange">
              Wyślij zapytanie
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="pricing-main__details">
            <h2>
              Psycholog Warszawa cena. Koszt wizyty u psychologa w Warszawie
            </h2>
            <p>
              Zastanawiasz się nad psychoterapią w Warszawie, ale masz
              wątpliwości, czy na pewno stać Cię na taką konsultację?
              <strong> Ile kosztuje wizyta u psychologa?</strong> Cena jest
              uzależniona od kilku czynników. Istnieje kilka rodzajów
              psychoterapii, badań psychologicznych i specjalności, które
              dotyczą określonych warunków zdrowia psychicznego, sytuacji i grup
              wiekowych. Jeśli myślisz o rozpoczęciu psychoterapii lub
              potrzebujesz innej pomocy, sprawdź,
              <strong> ile kosztuje psycholog w Warszawie.</strong>
            </p>
            <h2>
              Ile kosztuje psycholog? Cena jednej wizyty, cennik wizyt w
              Warszawie
            </h2>
            <img
              src={require("assets/images/psycholog-Warszawa-cena.jpg").default}
              alt="psycholog Warszawa cena"
            />
            <p>
              Jedną z najczęstszych wątpliwości osób decydujących się na terapię
              jest to, ile kosztuje psycholog. Cena psychoterapii to jedna
              sesja, która najczęściej trwa 50, 60 lub 90 minut. Opłata za
              konsultację psychologiczną w naszej poradni zaczyna się od 170 zł.
              Wartość ta ustalana jest z uwzględnieniem kilku kryteriów, takich
              jak czas pracy, specjalizacja zawodowa oraz forma świadczenia
              usług. Na przykład za jedną wizytę terapii dla par, która trwa
              godzinę, trzeba zapłacić 200 zł.
            </p>
            <p>
              Istnieje jednak wiele różnych wartości, więc podczas wyszukiwania
              w naszym cenniku znajdziesz ceny niższe lub wyższe niż te podane
              wyżej. Sesje zwykle odbywają się w dwóch formach: stacjonarnie lub
              online. Są dobierane zgodnie z tym, co jest najlepsze dla
              psychologa i pacjenta.
            </p>
            <h2>Psycholog online – psycholog Warszawa – cena za sesję</h2>
            <p>
              Jeśli zdecydujesz się na konsultację na odległość, możesz nawiązać
              kontakt i kontynuować poszczególne sesje, ale bez konieczności
              udawania się do gabinetu psychologa. Jest to dobre rozwiązanie:
            </p>
            <ul>
              <li>
                dla osób, które mieszkają daleko od Warszawy lub Wołomina;
              </li>
              <li>
                gdy ma się ograniczoną mobilność, małą elastyczność czasową lub
                innego rodzaju przeszkodę w dojeździe do poradni;
              </li>
              <li>
                gdy potrzebne jest zachowanie wyższego stopnia intymności.
              </li>
            </ul>
            <p>
              Możliwe jest przeprowadzenie odpowiedniej wideokonferencji bez
              wychodzenia z domu. <strong>Cena za sesję online</strong> nie
              różni się od kwoty za konsultację na miejscu. Jednak taka forma
              terapii pozwala zaoszczędzić pieniądze na dojazdy do psychologa.
            </p>
            <h2>
              Psychoterapia Warszawa – cena za długoterminową pomoc. Ceny
              psychoterapii
            </h2>
            <p>
              W zależności od rodzaju problemu czasami potrzebna jest więcej niż
              jedna wizyta u psychologa. Cena za miesiąc zależy od tego, ile
              sesji terapeutycznych odbywa się w tym okresie. Najczęściej
              wybierane są terminy spotkań co tydzień lub co dwa tygodnie.
              Psycholog zaleca odpowiednią częstotliwość na podstawie
              zrozumienia przypadku, ale to pacjent decyduje, ile sesji
              terapeutycznych odbędzie w miesiącu. Ta sama koncepcja dotyczy
              tego, jak długo powinna trwać{" "}
              <a href="/psycholog-warszawa/" target="_blank">
                psychoterapia w Warszawie
              </a>
              . Cena za koszt całego leczenia będzie więc uzależniona od rodzaju
              pomocy oraz czasu poświęconego na terapię.
            </p>
            <h2>
              Ile kosztuje psycholog dziecięcy? Ceny usług psychologicznych
              Warszawa i okolice
            </h2>
            <img
              src={require("assets/images/psycholog-cena.jpg").default}
              alt="psycholog cena"
            />
            <p>
              Terapia dzieci jest jedną z najważniejszych i najczęściej
              stosowanych metod opieki psychologicznej. Może być przeprowadzana
              zarówno osobiście, jak i online.{" "}
              <strong>
                Koszt konsultacji z psychologiem dziecięcym w Warszawie wynosi
                170 zł za 50-minutowe spotkanie.
              </strong>
            </p>
            <p>
              W poradni Spokój w Głowie możesz liczyć także na pomoc innych
              specjalistów pracujących z dziećmi lub nastolatkami. Są to m.in.
              psychoonkolog, neuropsycholog, dietetyk kliniczny i logopeda. Cenę
              za wizytę u każdego z tych praktyków znajdziesz w naszym cenniku.
            </p>
            <h2>Ile kosztuje diagnoza ADHD i badanie spektrum autyzmu?</h2>
            <p>
              Ocena i diagnoza ADHD to skrupulatne zadanie, które musi być
              wykonane przez konkretnych specjalistów, takich jak
              neuropsycholog, psycholog i logopeda. Tym samym postawienie
              diagnozy, przekazanie i nakierowanie na właściwe leczenie wymaga
              czasu i spotkania na miejscu w poradni. Podobnie jest w przypadku
              badania spektrum autyzmu. Tutaj również ważna jest kompleksowa
              ocena psychologiczna.
            </p>
            <p>
              Każde badanie musi poprzedzić wywiad diagnostyczny. Może też
              zaistnieć konieczność przeprowadzenia dodatkowych konsultacji lub
              testów, jeśli diagnosta uzna to za konieczne. Dlatego warto bliżej
              przejrzeć cały cennik świadczeń specjalistów w zakładce{" "}
              <a href="/testy-psychologiczne/" target="_blank">
                „Testy psychologiczne”
              </a>
              .
            </p>
            <h2>Dlaczego wizyta u psychologa jest płatna?</h2>
            <p>
              „Dlaczego psycholog jest taki drogi? Czemu trzeba płacić za wizytę
              u psychologa?” – te i inne pytania  często pojawiają się w głowach
              osób, które chcą rozpocząć terapię. Przede wszystkim należy
              pamiętać, że opłaty uiszcza się w placówkach prywatnych. Do nich
              nie trzeba skierowania, a czas oczekiwania na wizytę jest krótszy
              niż na NFZ. Dzięki temu można liczyć na pomoc profesjonalistów bez
              zbędnej zwłoki.
            </p>
            <p>
              Poza tym zostanie psychologiem, kosztuje czas i pieniądze. To
              wymaga lat studiów psychologicznych, ale na tym się nie kończy.
              Inwestuje się w kursy, szkolenia, specjalizacje, studia
              podyplomowe, doktoranckie, doświadczenie zawodowe w instytucjach,
              w sieci publicznej i prywatnej. Istotne są również wydatki na
              utrzymanie fizycznej przestrzeni obiektu. W końcu praca dobrego
              psychologa wymaga wiele wysiłku i wiedzy, a za trafną diagnozę i
              dobrą pomoc, naprawdę warto zapłacić.
            </p>
            <h2>Psycholog Warszawa – cena, na jaką Cię stać!</h2>
            <img
              src={
                require("assets/images/koszt-wizyty-u-psychologa-w-Warszawie.jpg")
                  .default
              }
              alt="kosz wizyty u psychologa w Warszawie"
            />
            <p>
              W Poradni Spokój w Głowie wychodzimy naprzeciw oczekiwaniom
              pacjentów i oferujemy dużo taniej nasze usługi psychologiczne.
              <strong> Cena za wizytę u psychologa w Warszawie</strong> w naszej
              placówce przy ul. Mochtyńska 65 Warszawa Białołęka,
              <strong>
                {" "}
                nie jest wyższa od kosztów, jakie ponoszą klienci poradni w
                Wołominie
              </strong>
              . Wszystko po to, by każdy mógł skorzystać z profesjonalnej
              pomocy. Więcej o tym, jak wielu osobom pomogliśmy w wołomińskiej
              placówce, przeczytasz w opiniach naszych pacjentów.
            </p>
            <p>
              Opinie o naszej placówce w Wołominie znajdziesz tutaj:{" "}
              <a
                href="https://www.google.com/search?q=spokojwglowie&ei=0A8iYpC3AuWkrgTPzJ3QCg&ved=0ahUKEwiQ36Duw6z2AhVlkosKHU9mB6oQ4dUDCA4&uact=5&oq=spokojwglowie&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgARKBAhBGABKBAhGGABQAFgAYIkDaABwAXgAgAFfiAFfkgEBMZgBAKABAcABAQ&sclient=gws-wiz-serp#lrd=0x471ec5505346b779:0x67dbde625b23de1a,1,,"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Opinie Spokój w Głowie
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      {bookingModal && <BookingModal setBookingModal={setBookingModal} />}
    </Layout>
  );
};

export default PricingPage;
